"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadingReducer = exports.componentReducer = exports.stepReducer = exports.offerReducer = void 0;
const i18next_1 = require("i18next");
const CommonHelpers_1 = require("../helpers/CommonHelpers");
const CheckoutContext_1 = require("./CheckoutContext");
function offerReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case CheckoutContext_1.offerActionType.update_offer:
            return {
                ...state,
                ...payload
            };
        case CheckoutContext_1.offerActionType.stayPeriod:
            return {
                ...state,
                stayPeriod: payload
            };
        case CheckoutContext_1.offerActionType.promoCode:
            return {
                ...state,
                promoCode: payload
            };
        case CheckoutContext_1.offerActionType.promoCodeType:
            return {
                ...state,
                promoCodeType: payload
            };
        case CheckoutContext_1.offerActionType.isPrePaid:
            return {
                ...state,
                isPrepaid: payload
            };
        case CheckoutContext_1.offerActionType.isStudent:
            return {
                ...state,
                isStudent: payload
            };
        case CheckoutContext_1.offerActionType.isReturning:
            return {
                ...state,
                isReturning: payload
            };
        case CheckoutContext_1.offerActionType.addOnProducts:
            return {
                ...state,
                addOnProducts: payload
            };
        case CheckoutContext_1.offerActionType.isExtended:
            return {
                ...state,
                isExtended: payload
            };
        case CheckoutContext_1.offerActionType.bedroomOfferId:
            return {
                ...state,
                bedroomOfferId: payload
            };
        case CheckoutContext_1.offerActionType.guid:
            return {
                ...state,
                guid: payload
            };
        case CheckoutContext_1.offerActionType.stripeKeys:
            return {
                ...state,
                stripeKeys: payload
            };
        case CheckoutContext_1.offerActionType.payMethodDiscountPercent:
            return {
                ...state,
                payMethodDiscountPercent: payload
            };
        default: throw new Error(`No ${type} found in offerReducer`);
    }
}
exports.offerReducer = offerReducer;
function stepReducer(state, action) {
    const { type, payload } = action;
    const temp = { ...state, stepActionWarning: false };
    switch (type) {
        case CheckoutContext_1.stepActionType.first:
            return {
                ...temp,
                activeStep: state.stepperOptions[0].value,
                activeStepLabel: state.stepperOptions[0].label,
                activeStepIndex: 0
            };
        case CheckoutContext_1.stepActionType.to:
            if (payload && payload.targetStep) {
                return {
                    ...temp,
                    activeStep: payload.targetStep,
                    activeStepLabel: state.stepperOptions[(0, CommonHelpers_1.findStepIndex)(payload.targetStep, state.stepperOptions)].label,
                    activeStepIndex: (0, CommonHelpers_1.findStepIndex)(payload.targetStep, state.stepperOptions)
                };
            }
            else {
                throw new Error(`targetStep is missing`);
            }
        case CheckoutContext_1.stepActionType.next:
            return {
                ...temp,
                activeStep: state.stepperOptions[state.activeStepIndex + 1].value,
                activeStepLabel: state.stepperOptions[state.activeStepIndex + 1].label,
                activeStepIndex: state.activeStepIndex + 1
            };
        case CheckoutContext_1.stepActionType.previous:
            if (state.activeStepIndex === 0) {
                window.history.back();
                return { ...temp };
            }
            else if (state.activeStep === "residents_information") {
                if ((payload && !payload.hasBankTransferSlip) || !payload) {
                    return { ...state, stepActionWarning: true };
                }
                return { ...temp };
            }
            else {
                return {
                    ...temp,
                    activeStep: state.stepperOptions[state.activeStepIndex - 1].value,
                    activeStepLabel: state.stepperOptions[state.activeStepIndex - 1].label,
                    activeStepIndex: state.activeStepIndex - 1
                };
            }
        case CheckoutContext_1.stepActionType.update_options: {
            const index = (0, CommonHelpers_1.findStepIndex)("addon_requests", state.stepperOptions);
            const tempOptions = [...state.stepperOptions];
            if (payload?.region === "sg") {
                // WREC
                if (payload?.propertyId === "WREC") {
                    tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('Tenancy Agreement');
                }
                else if (payload?.propertyId === "WSHS" && payload.stayPeriod === "shortStay") {
                    tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = "House Rules";
                }
                else {
                    tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('License agreement');
                }
            }
            else if (payload && payload.stayPeriod && payload.stayPeriod === "shortStay") {
                tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('House Rules Agreement');
                if (index > -1) {
                    tempOptions.splice(index, 1);
                }
            }
            else if (payload && payload.stayPeriod && payload.stayPeriod === "longStay") {
                if (index > -1 && payload.region === "sg") {
                    tempOptions.splice(index, 1);
                }
            }
            else if (!payload?.stayPeriod && payload?.region === "jp") {
                tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('Lease Agreement');
            }
            else if (!payload?.stayPeriod && payload?.region === "kr") {
                if (payload?.propertyId === "WSSP") {
                    tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('License agreement');
                }
                else {
                    tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('Lease Agreement');
                }
            }
            else {
                throw new Error(`stayPeriod is missing to update step options`);
            }
            return {
                ...state,
                stepperOptions: tempOptions
            };
        }
        case CheckoutContext_1.stepActionType.add:
            if (payload && payload.afterStep && payload.newStep) {
                temp.stepperOptions.splice((0, CommonHelpers_1.findStepIndex)(payload.afterStep, state.stepperOptions) + 1, 0, payload.newStep);
                return {
                    ...temp
                };
            }
            else {
                throw new Error(`wrong/insufficient payload to add step`);
            }
        case CheckoutContext_1.stepActionType.remove:
            if (payload && payload?.targetStep) {
                const removeIndex = (0, CommonHelpers_1.findStepIndex)(payload?.targetStep, state.stepperOptions);
                if (removeIndex > -1) {
                    state.stepperOptions.splice(removeIndex, 1);
                    return {
                        ...state,
                        stepperOptions: state.stepperOptions
                    };
                }
                else
                    throw new Error(`Step ${payload?.targetStep} not found`);
            }
            else {
                throw new Error(`targetStep is missing`);
            }
        case CheckoutContext_1.stepActionType.cancelStepAction:
            return { ...temp };
        case CheckoutContext_1.stepActionType.japanese_booking_application:
            return {
                ...temp,
                activeStep: "japanese_booking_application",
                activeStepLabel: "Booking Application",
                activeStepIndex: 1,
                stepperOptions: [
                    { value: 'home_details', label: (0, i18next_1.t)('Home Details') },
                    {
                        value: "japanese_booking_application",
                        label: (0, i18next_1.t)('Booking Application')
                    },
                    { value: 'japanese_booking_application_done', label: (0, i18next_1.t)('Done') }
                ]
            };
        default: throw new Error(`No ${type} found in stepReducer`);
    }
}
exports.stepReducer = stepReducer;
function componentReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case CheckoutContext_1.componentActionType.loginModal:
            return {
                ...state,
                loginModal: payload.component,
                loginModalStep: payload.step ? payload.step : 0
            };
        case CheckoutContext_1.componentActionType.studentModal:
            return {
                ...state,
                studentModal: payload.component,
            };
        case CheckoutContext_1.componentActionType.extendReminder:
            return {
                ...state,
                extendReminder: payload.component
            };
        case CheckoutContext_1.componentActionType.confirmPopup:
            return {
                ...state,
                confirmPopup: payload.component,
                confirmPopupType: payload.type,
            };
        case CheckoutContext_1.componentActionType.japaneseModal:
            return {
                ...state,
                japaneseModal: payload.component,
            };
        default: throw new Error(`No ${type} found in componentReducer`);
    }
}
exports.componentReducer = componentReducer;
function loadingReducer(state, action) {
    const { type, payload } = action;
    const temp = { ...state, };
    temp[type] = payload;
    for (const loading in temp) {
        if (loading !== "globalLoading" && temp[loading]) {
            return {
                ...temp, globalLoading: true
            };
        }
    }
    return { ...temp, globalLoading: false };
}
exports.loadingReducer = loadingReducer;
